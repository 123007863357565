import * as React from 'react'
import { Link, graphql } from 'gatsby'

import Bio from '../components/bio'
import Layout from '../components/layout'
import SEO from '../components/seo'

const BlogIndex = ({ data, location }) => {
  const siteTitle = data.site.siteMetadata?.title || `Title`
  const siteAuthor = data.site.siteMetadata?.author?.name || `Author`
  const notes = data.allMdx.nodes

  if (notes.length === 0) {
    return (
      <Layout location={location} title={siteTitle}>
        <SEO title={siteAuthor} />
        <Bio />
        <p>
          No notes found. Add markdown notes to "content/notes" (or the
          directory you specified for the "gatsby-source-filesystem" plugin in
          gatsby-config.js).
        </p>
      </Layout>
    )
  }

  return (
    <Layout location={location} title={siteTitle}>
      <SEO title={siteAuthor} />
      <Bio />
      <ol style={{ listStyle: `none` }}>
        {notes.map((note) => {
          const title = note.frontmatter.title || note.fields.slug

          return (
            <li key={note.fields.slug}>
              <article
                className="note-list-item"
                itemScope
                itemType="http://schema.org/Article"
              >
                <header>
                  <h2>
                    <Link to={note.fields.slug} itemProp="url">
                      <span itemProp="headline">{title}</span>
                    </Link>
                  </h2>
                  <small>Updated on {note.frontmatter.lastUpdated}</small>
                </header>
                <section>
                  <p
                    dangerouslySetInnerHTML={{
                      __html: note.frontmatter.description || note.excerpt,
                    }}
                    itemProp="description"
                  />
                </section>
              </article>
            </li>
          )
        })}
      </ol>
    </Layout>
  )
}

export default BlogIndex

export const pageQuery = graphql`
  query {
    site {
      siteMetadata {
        title
        author {
          name
        }
      }
    }
    allMdx(sort: { fields: [frontmatter___lastUpdated], order: DESC }) {
      nodes {
        excerpt
        fields {
          slug
        }
        frontmatter {
          date(formatString: "MMMM DD, YYYY")
          lastUpdated(formatString: "MMMM DD, YYYY")
          title
          description
        }
      }
    }
  }
`
